<template>
	<div class="mapContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">营销网络</div>
			<div class="font2"><span class="font2-text">MARKETING NET</span><span class="splitLine"></span></div>
		</div>
		<div id="mapMain" style="width: 100%;height:800px;"></div>
	</div>
</template>

<script>
	import "echarts/map/js/china.js"
	import {
		getArticles
	} from '@/api/request'
	export default {
		name: 'hello',
		data() {
			return {
				geoCoordMap: {
					"南京": [118.803572, 32.067834]
				},
				fromdata: '南京',
				XAData: [],
				option: {
					backgroundColor: "#175068",
					tooltip: {
						trigger: 'item',
						formatter: function(params) {
							if (params.value) {
								return params.name + ' : ' + params.value[2];
							} else {
								return params.name;
							}
						}
					},

					legend: {
						orient: 'horizontal',
						left: '27%',
						top: '5%',
						data: ['高线'],
						textStyle: {
							color: '#ffffff'
						},
						icon: 'circle'
					},
					geo: {
						map: 'china',
						zlevel: 12,
						layoutCenter: ['50%', '50%'],
						roam: true,
						layoutSize: "100%",
						zoom: 1.2,
						label: {
							normal: {
								show: true, //地图上的省份名称是否显示
								textStyle: {
									fontSize: 12,
									color: '#43D0D6'
								}
							},
							emphasis: {
								show: true
							}
						},
						itemStyle: {
							normal: {
								color: '#0d3e53',
								borderWidth: 1.1,
								borderColor: '#43D0D6'
							},
							emphasis: {
								areaColor: '#43D0D6'
							}
						}
					},
					series: []
				}
			}
		},
		mounted() {

			this.loadSyncData();
		},
		methods: {
			loadSyncData() {
				let _this = this;
				getArticles({
					currentPage: 1,
					pageSize: 1000,
					topicCode: "citys"
				}).then(resp => {
					let data = resp.data.result.data;

					if (data) {
						data.forEach(function(city) {

							let cityConfig = JSON.parse(city.aSummary);

							let cityName = Object.keys(cityConfig)[0];
							let lonlat = cityConfig[cityName];
							if (cityConfig.highLight) {
								_this.option.series.push({
									name: cityName,
									type: "scatter",
									zlevel: 20,
									color: "#f00",
									coordinateSystem: "geo",
									symbolSize: 8,
									itemStyle: {
										normal: {
											color: "#f00"
										}
									},
									tooltip: {
										show: false
									},
									data: [lonlat]
								})
							}

							_this.geoCoordMap[cityName] = lonlat;
							_this.XAData.push([{
								name: '南京'
							}, {
								name: cityName
							}]);
						});
						_this.drawLine();
					}

				});
			},
			drawLine() {
				var resultdatas = [];
				let _this = this;
				var convertData1 = function(data) {
					let res = [];
					for (let i = 0; i < data.length; i++) {
						let dataItem = data[i];
						let fromCoord = _this.geoCoordMap[dataItem[0].name];
						let toCoord = _this.geoCoordMap[dataItem[1].name];
						if (fromCoord && toCoord) {
							res.push({
								fromName: dataItem[0].name,
								toName: dataItem[1].name,
								coords: [fromCoord, toCoord]
							});
						}
					}
					return res;
				};

				[
					[_this.fromdata, _this.XAData]
				].forEach(function(item, i) {
					let fromPoint = {
						name: _this.fromdata,
						value: _this.geoCoordMap[_this.fromdata]
					};
					console.log(fromPoint);
					_this.option.series.push({
							name: '江苏',
							type: 'scatter',
							zlevel: 20,
							color: '#f00',
							coordinateSystem: 'geo',
							symbolSize: 10,
							itemStyle: {
								normal: {
									color: '#f00'
								}
							},
							tooltip: {
								show: false,
							},
							data: [_this.geoCoordMap[_this.fromdata]]
						}, {
							type: 'lines',
							zlevel: 15,
							effect: {
								show: true,
								period: 4,
								trailLength: 0,
								symbol: 'arrow',
								symbolSize: 7,
							},
							lineStyle: {
								normal: {
									width: 1.2,
									opacity: 0.6,
									curveness: 0.2,
									color: '#F19000'
								}
							},
							data: convertData1(item[1])
						},
						//出发点
						{
							type: 'effectScatter',
							coordinateSystem: 'geo',
							zlevel: 15,
							rippleEffect: {
								period: 4,
								brushType: 'stroke',
								scale: 4
							},
							symbol: 'circle',
							symbolSize: 15,
							itemStyle: {
								normal: {
									color: '#F19000'
								}
							},
							tooltip: {
								show: false,
							},
							data: [fromPoint]
						},
						/*到达点*/
						{
							type: 'effectScatter',
							coordinateSystem: 'geo',
							rippleEffect: {
								period: 4,
								brushType: 'stroke',
								scale: 4
							},
							zlevel: 15,
							label: {
								normal: {
									show: false
								}
							},
							tooltip: {
								show: false,
							},
							symbol: 'circle',
							symbolSize: 15,
							itemStyle: {
								normal: {
									color: '#F19000'
								}
							},
							data: item[1].map(function(dataItem) {

								let res = {
									name: dataItem[1].name,
									value: _this.geoCoordMap[dataItem[1].name],
								}
								console.log(res);
								return res;
							}),
						});
				});
				// 基于准备好的dom，初始化echarts实例
				let myChart = this.$echarts.init(document.getElementById('mapMain'))
				// 绘制图表
				myChart.setOption(this.option);
			}
		}
	}
</script>

<style>
	.mapContainer {
		background-color: #175068;
	}

	.content_container {
		min-height: 0;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}
</style>
